import Image from "next/image";
import Link from "next/link";

const navigation = {
  products: [
    { name: "Creator Toolkit", href: "https://rfoxvalt.com/creator-toolkit" },
    { name: "CitiXens", href: "https://rfoxvalt.com/citixens" },
    { name: "Apartments", href: "https://rfoxvalt.com/apartments" },
  ],
  sites: [
    { name: "RFOX", href: "https://rfox.com" },
    { name: "RFOX NFTs", href: "https://rfoxnfts.com" },
    { name: "RFOX Finance", href: "https://app.rfox.finance" },
    { name: "RFOX Media", href: "https://rfoxmedia.com" },
    { name: "KOGs", href: "https://kogs.gg" },
  ],
  legals: [
    { name: "Privacy Policy", href: "https://rfoxvalt.com/privacy" },
    { name: "Terms of Use", href: "https://rfoxvalt.com/terms-of-use" },
    {
      name: "Metaverse T&Cs",
      href: "https://rfoxvalt.com/terms-and-conditions",
    },
  ],
};

const Footer: React.FC = () => {
  return (
    <footer className="bg-black" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-full px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="lg:grid lg:grid-cols-4 lg:gap-8">
          <div className="space-y-4 md:space-y-8 lg:col-span-2">
            <Image
              src="/images/rfox-valt.svg"
              alt="rfox valt"
              width={143}
              height={36}
              className="w-auto"
            />
            <p className="text-base text-light-100">
              RFOX VALT is a fully immersive, virtual reality-driven retail and
              entertainment experience in the metaverse.
            </p>
          </div>
          <div className="mt-8 grid gap-4 md:mt-12 md:grid-cols-3 md:gap-8 lg:col-span-2 xl:mt-0">
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider text-light-100">
                Products
              </h3>
              <ul role="list" className="mt-4 space-y-4">
                {navigation.products.map((item) => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      className="text-base text-light-250 hover:text-primary-100"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider text-light-100">
                Sites
              </h3>
              <ul role="list" className="mt-4 space-y-4">
                {navigation.sites.map((item) => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      className="text-base text-light-250 hover:text-primary-100"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider text-light-100">
                  Legals
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legals.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-base text-light-250 hover:text-primary-100"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()}{" "}
            <span className="font-bold text-white">RFOX VALT</span>. All Rights
            Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
